import React, { useState, createContext } from "react"

export const onDemandPlayerContext = createContext()

export const OnDemandPlayerProvider = ({ children }) => {
  const [onDemandPlayerState, setOnDemandPlayerState] = useState({
    eventType: "",
    presentationId: "",
    status: ""
  })

  return (
    <onDemandPlayerContext.Provider value={[onDemandPlayerState, setOnDemandPlayerState]}>
      {children}
    </onDemandPlayerContext.Provider>
  )
}
