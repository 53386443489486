import React, { createContext, useState, useEffect } from 'react';
import SCClientSocket from 'socketcluster-client';

export const SocketContext = createContext({});

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  let reconnectTimeout;

  const connectSocket = () => {
    const socketInstance = SCClientSocket.connect({
      hostname: process.env.SOCKET_CLUSTER_HOST,
      port: process.env.SOCKET_CLUSTER_PORT,
    });

    socketInstance.on('error', (err) => {
      setIsConnected(false);
      if (!reconnectTimeout) {
        reconnectTimeout = setTimeout(connectSocket, 5000);
      }
    });

    socketInstance.on('connect', () => {
      setIsConnected(true);
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    });

    socketInstance.on('disconnect', () => {
      setIsConnected(false);
    });

    setSocket(socketInstance);
  };

  // Check if socket is null and reconnect if needed
  useEffect(() => {
    if (!socket) {
      connectSocket();
    }
  }, [socket]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket, isConnected }}>
      {children}
    </SocketContext.Provider>
  );
};
