import React, { useState, createContext } from 'react';
export const pageASideContext = createContext();

export const PageASideProvider = ({ children }) => {
  const [pageAsideState, setpageAsideState] = useState(true)
  return (
    <pageASideContext.Provider value={[pageAsideState, setpageAsideState]}>
      {children}
    </pageASideContext.Provider>
  )
};
