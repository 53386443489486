export const getNestedObjectValue = (a, b) => {
  return b.split(".").reduce(function (a, b) {
    return "undefined" == typeof a || null === a ? a : a[b]
  }, a)
}
export const isBoolean = (value)=>{
  if(value){
    return Boolean(JSON.parse((value.toString()).toLowerCase()));
  }
}
