export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const TOKEN_EXPIRED = "TOKEN_EXPIRED"

export const STRING_REGEX = /^[A-Za-z',.]+$/

export const PASSWORD_AND_USERNAME_INCORRECT = "PASSWORD_AND_USERNAME_INCORRECT"
export const PASSWORD_AND_USERNAME_DIDNOT_MATCH = "Your email and password combination did not match."
export const USERNAME_DIDNOT_MATCH = "Your email did not match."
const PRESENTATION_STATUS_LIVE = "live"
const PRESENTATION_STATUS_ONDEMAND = "ondemand"
const PLAYER_EVENT_PLAY = "play"
const PLAYER_EVENT_PAUSE = "pause"
const REGISTRATION_TYPE_MODERATED = "moderated"
const REGISTRATION_TYPE_NONE = "none"
const LOGIN_TYPE_NO_PASSWORD = "no_password"
export {
  PRESENTATION_STATUS_LIVE,
  PRESENTATION_STATUS_ONDEMAND,
  PLAYER_EVENT_PLAY,
  PLAYER_EVENT_PAUSE,
  REGISTRATION_TYPE_MODERATED,
  REGISTRATION_TYPE_NONE,
  LOGIN_TYPE_NO_PASSWORD
}
