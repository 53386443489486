module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://matomo.dve.investis-live.com","siteUrl":"https://feature2.dve.investis-live.com","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","disableCookies":true,"trackLoad":false,"dev":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NHGSK2N","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"Virtual Event"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","name":"Virtual event","short_name":"Virtual event","start_url":"/","background_color":"#fff","theme_color_in_head":false,"display":"standalone","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d92de1d523f81b38c9f1b661cb06ff7a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
