
export function handleJoinLiveViewer(socket, presentationId) {
  try {
    const userId = JSON.parse(window.localStorage.veUser)?.userId;
    socket.emit('viewerJoin', {
      presentationId: presentationId,
      userId: userId,
    });
  } catch (error) {
    console.error('Error in handleJoinLiveViewer:', error);
  }
}

export function handleRemoveLiveViewer(socket, presentationId) {
  try {
    const userId = JSON.parse(window.localStorage.veUser)?.userId;
    socket.emit('viewerDisconnection', {
      presentationId: presentationId,
      userId: userId,
    });
  } catch (error) {
    console.error('Error in handleRemoveLiveViewer:', error);
  }
}
