import logo from "../images/logo.svg"
import bannerImage from "../images/VE-banner.jpg"
import LiveEventImage from "../images/LiveEvent.jpg"

const logoalt = "InvestisDigital"
const isMatomoEnabled = (process.env.MATOMO_URL 
    && process.env.MATOMO_CONTAINER_ID 
    && process.env.MATOMO_SITE_ID) ? true : false;

export { logo, logoalt, bannerImage, LiveEventImage, isMatomoEnabled }
