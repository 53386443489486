import React, { useState, createContext } from "react"

export const livePlayerContext = createContext()

export const LivePlayerProvider = ({ children }) => {
  const [livePlayerState, setlivePlayerState] = useState({
    eventType: "",
    presentationId: "",
    status: ""
  })

  return (
    <livePlayerContext.Provider value={[livePlayerState, setlivePlayerState]}>
      {children}
    </livePlayerContext.Provider>
  )
}
