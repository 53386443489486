import React, { useState, useEffect } from "react";
import Text from "atomic-core/components/patterns/01-atoms/texts/text/Text";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import * as moment from "moment";

const Notification = props => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  
  //Function used mainly to identify if the link in notification is of the site VE site or external
  const extractHostname = (url) => {
    let hostname;

    //find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  //Function to convert any link in notification message to anchor links
  const URLify =(string) => {
    const urls = string.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g);
    if (urls) {
      urls.forEach(function (url) {
        let hostname = extractHostname(url);
        let target = (window.location.hostname == hostname) ? "_self" : "_blank";
        string = string.replace(url, `<a class="link text-underline color-monochrome-1" target="${target}" href="${url}">${url}</a>`);
      });
    }
    return string;
  }

  useEffect(() => {
    if(props.message && props.message != sessionStorage.getItem('notificationMessage') && props.time){  
      setNotificationVisible(true);
    }else{
      setNotificationVisible(false);
    }
  }, [props])

  const closeNotification = () => {
    sessionStorage.setItem('notificationMessage',  props.message);
    setNotificationVisible(false);
  }
  
  return (
    <div className={`notification background-color-primary-4 color-monochrome-1 font-secondary-bold 
        ${notificationVisible ? "" : "hidden"}`
    }>
        {props.message && props.time && 
          <>
            <Text globalModifiers="notification-time mt-5 ml-5 mr-20 text-align-center text-size-small">
              {moment.utc(props.time).format("HH:mm")} GMT
            </Text>
            <Text globalModifiers="notification-message text-size-medium text-align-center mt-20 mb-20">
            <div dangerouslySetInnerHTML={{__html: URLify(props.message)}}></div>
            </Text>
            <FontAwesomeIcon icon={faTimesCircle} className="pointer-cursor close-btn mt-5 mr-5 ml-20" onClick={closeNotification} />
          </>
        }
    </div>
  )
}

export default Notification
