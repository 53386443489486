const playerEvent = async ({ eventType,presentationId,status}) => {
  const params = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      eventId: process.env.EVENT_ID,
      presentationId: presentationId,
      type:eventType,
      status:status,
    }),
  }
  await fetch(process.env.API_URL + "/player-eventv2", params)
}

export { playerEvent }
